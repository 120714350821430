


import { Trans } from "@lingui/react";
import IconButton from "components/AccountDrawer/IconButton";
import { BaseButton } from "components/Button";
import { AutoRow } from "components/Row";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffect, useRef } from "react";
import { Settings, X } from "react-feather";
import { Text } from "rebass";
import styled, { useTheme } from "styled-components/macro";
import { BREAKPOINTS, ThemedText } from "theme";
import { flexColumnNoWrap } from "theme/styles";
import { Z_INDEX } from "theme/zIndex";
const DRAWER_TOP_MARGIN_MOBILE_WEB = "72px";

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  width: 100%;
  padding: 32px;
  flex: 1;
`;
const ScrimBackground = styled.div<{ open: boolean }>`
  z-index: ${Z_INDEX.modalBackdrop};
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? "auto" : "none")};
    transition: opacity ${({ theme }) => theme.transition.duration.medium}
      ease-in-out;
  }
`;
const Scrim = ({ onClick, open }: { onClick: () => void; open: boolean }) => {
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width < BREAKPOINTS.sm && open)
      document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [open, width]);

  return <ScrimBackground onClick={onClick} open={open} />;
};

const AccountDrawerWrapper = styled.div<{ open: boolean }>`
  overflow: hidden;
  width: 500px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    z-index: ${Z_INDEX.modal};
    position: absolute;
    margin-right: 0;
    top: ${({ open }) =>
      open ? `calc(-1 * (100% - ${DRAWER_TOP_MARGIN_MOBILE_WEB}))` : 0};

    width: 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: unset;
    transition: top ${({ theme }) => theme.transition.duration.medium};
  }

  @media screen and (min-width: 1440px) {
  }

  border-radius: 12px;
  font-size: 16px;
  border-radius: 28px;

  background: #171717;
  z-index: 1041;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    mask-size: 100% 50%;
    mask-repeat: no-repeat;
    z-index: -1;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background: rgba(255, 255, 255, 0.04);
  font-size: 16px;
  * {
    font-size: 16px !important;
  }
  padding: 13px;
  margin: 10px 0;
  color: #fff;
  border: none;
  &:focus {
  }
  &:hover {
  }
  &:active {
  }
  &:disabled {
    cursor: auto;
    outline: none;
    filter: grayscale(1);
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(22px);
  overflow: hidden;
  position: fixed;
  z-index: ${Z_INDEX.fixed};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible;
  }
`;
const AccountDrawerScrollWrapper = styled.div`
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }

  border-radius: 12px;
`;

const SwitchWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const ToggleSwitch = styled.div<{ checked: boolean }>`
  width: 40px;
  height: 25px;
  background: ${({ checked }) =>
    !checked ? "#fff" : "rgba(255, 255, 255, 0.04)"};
  border-radius: 20px;
  position: relative;
  transition: background 0.3s ease-in-out;
  padding: 10px;

  &::before {
    content: "";
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: ${({ checked }) => (true ? "20px" : "2px")};
    transition: left 0.3s ease-in-out;
  }
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  padding: 8px;
  width: 100%;
`;

const ProgressTrack = styled.div`
  flex: 1;
  height: 50px;
  background: rgba(255, 255, 255, 0.04);
  margin: 0 10px;
  border-radius: 12px;
  position: relative;
`;

const ProgressFill = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: white;
  border-radius: 12px;
  transition: width 0.3s ease-in-out;
  position: relative;

  &::before {
    content: "|||";
    position: absolute;
    top: 12px;
    right: 6px;
    color: #000;
    transition: left 0.3s ease-in-out;
  }
`;

const PercentageBox = styled.div`
  width: 20%;
  height: 50px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

export default function SettingModal({ className , isOpen , onClose}: { className?: string , isOpen : boolean , onClose : () => void}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return (
    isOpen ? <Container>
    <AccountDrawerWrapper open={isOpen}>
      <AccountDrawerScrollWrapper
        ref={scrollRef}
        id="wallet-dropdown-scroll-wrapper"
      >
        <Wrapper data-testid="setting-modal">
          <AutoRow justify="space-between" width="100%" marginBottom="32px">
            <ThemedText.SubHeader fontWeight={500} className="flex gap-2">
              <Settings />
              Swap Setting
            </ThemedText.SubHeader>
            <IconButton
              Icon={X}
              onClick={onClose}
              data-testid="wallet-settings"
              className="!bg-transparent"
              
            />
          </AutoRow>
          <ThemedText.SubHeader
            fontWeight={500}
            className="flex gap-5 justify-between items-center"
            marginBottom="32px"
          >
            <AutoRow>
              <Text fontWeight={500} fontSize={16} color={theme.textPrimary}>
                Auto-Limit Order
              </Text>
              <Text
                color={theme.textSecondary}
                fontWeight={500}
                fontSize={16}
              >
                <Trans id="Automatically places limit orders aligned with the RWA issuance/redemption prices."></Trans>
              </Text>
            </AutoRow>
            <SwitchWrapper>
              <HiddenCheckbox checked={false} onChange={() => {}} />
              <ToggleSwitch checked={false} onClick={() => {}} />
            </SwitchWrapper>
          </ThemedText.SubHeader>
          <AutoRow justify="space-between" width="100%" marginBottom="32px">
            <Text fontWeight={500} fontSize={16} color={theme.textPrimary}>
              Tolerance
            </Text>
            <Text color={theme.textSecondary} fontWeight={500} fontSize={16}>
              <Trans id="Adjusts your tolerance to ensure your trade executes quickly while still getting the best price."></Trans>
            </Text>
          </AutoRow>
          <AutoRow>
            <ProgressBarWrapper>
              <ProgressTrack>
                <ProgressFill progress={40} />
              </ProgressTrack>
              <PercentageBox>0.5 %</PercentageBox>
            </ProgressBarWrapper>
          </AutoRow>
        </Wrapper>
      </AccountDrawerScrollWrapper>
    </AccountDrawerWrapper>
  </Container> : <></>
    
  );
}