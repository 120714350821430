import { Trans } from '@lingui/react'
import { Trace, TraceEvent } from '@uniswap/analytics'
import { InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { ChainId } from '@uniswap/smart-order-router'
import { Field } from '@uniswap/widgets'
import { ButtonError } from 'components/Button'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { loadingOpacityMixin } from 'components/Loader/styled'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import Input from 'components/NumericalInput'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { ArrowWrapper, SwapWrapper } from 'components/swap/styleds'
import SwapHeader from 'components/swap/SwapHeader'
import Widget from 'components/Widget'
import { AllowanceState } from 'hooks/usePermit2Allowance'
import React, { useState } from 'react'
import { ChevronDown } from 'react-feather'
import { Text } from 'rebass'
import useSwapStore from 'state/store'
import styled from 'styled-components'
import { flexRowNoWrap } from 'theme/styles'

import { ReactComponent as ArrowIcon } from '../../assets/images/dropdown.svg'
import SettingModal from './SettingModal'
import SwapShareModal from './SwapShareModal'

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
`

const SwitchWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const ToggleSwitch = styled.div<{ checked: boolean }>`
  width: 40px;
  height: 25px;
  background: ${({ checked }) => (!checked ? '#fff' : 'rgba(255, 255, 255, 0.04)')};
  border-radius: 20px;
  position: relative;
  transition: background 0.3s ease-in-out;
  padding: 10px;

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: ${({ checked }) => (true ? '20px' : '2px')};
    transition: left 0.3s ease-in-out;
  }
`

const StyledNumericalInput = styled(Input)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 36px;
  line-height: 44px;
  font-variant: small-caps;
  &::placeholder {
    color: #fff;
  }
  &:focus::placeholder {
    color: transparent;
  }
`

const SwapButton = styled.button<{ isActive: boolean; name: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 8px;
  height: 44px;
  width: 100px;
  margin-right: 10px;
  position: relative;
  color: ${({ isActive }) => (isActive ? 'white' : 'rgba(255, 255, 255, 0.500)')};
  transition: font-size 0.3s ease, background-color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 0px;
    width: 1px;
    height: ${({ name }) => (name !== 'dca' ? '20px' : '0px')};
    background-color: #282531;
  }
`

const RowBetween = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const StyledHeaderRow = styled(RowBetween)<{
  disabled: boolean
  open: boolean
}>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
  color: white;
`

const cryptoData = [
  {
    name: 'USDY',
    subtext: 'Ondo U.S. TBill',
    image: '/icons/USDY.svg',
  },
  {
    name: 'PAXG',
    subtext: 'PAX Gold',
    image: '/icons/eth/PAXG_Paxos.png',
  },
  {
    name: 'bCSPX',
    subtext: 'Backed Finance S&P500',
    image: '/icons/bcspx.webp',
  },
  {
    name: 'USDC',
    subtext: 'Circle USD',
    image: '/icons/USDC.png',
  },
]

const ArrowLeft = styled(ArrowIcon)`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;
  margin-left: 8px;
  transform: rotate(270deg);
  marginright: -3px !important;

  path {
    stroke: ${({ theme }) => theme.white};
    stroke-width: 2px;
  }
`
interface DualSwapModelProps {
  swapWidgetEnabled: boolean
  loadedInputCurrency?: any
  loadedOutputCurrency?: any
  className?: string
  allowedSlippage: any
  showConfirm: boolean
  trade: any
  tradeToConfirm: any
  handleAcceptChanges: () => void
  attemptingTxn: boolean
  txHash?: string
  recipient?: any
  handleSwap: () => void
  swapErrorMessage?: string
  handleConfirmDismiss: () => void
  swapQuoteReceivedDate?: any
  fiatValueTradeInput?: any
  fiatValueTradeOutput?: any
  SwapSection: React.ElementType
  InterfaceSectionName: any
  independentField: string
  showWrap: any
  inputAmount: any
  showMaxButton: boolean
  src: any
  handleTypeInput: (value: string) => void
  isSupportedChain: (chainId: number) => boolean
  handleMaxInput: () => void
  fiatValueInput?: any
  handleInputSelect: (currency: any) => void
  dst: any
  BrowserEvent: any
  routeIsSyncing: boolean
  ArrowContainer: React.ElementType
  onSwitchTokens: () => void
  fetchTrade: () => void
  outputAmount: any
  OutputSwapSection: React.ElementType
  theme: any
  showDetailsDropdown: boolean
  handleTypeOutput: (value: string) => void
  fiatValueOutput?: any
  stablecoinPriceImpact?: any
  handleOutputSelect: (currency: any) => void
  showPriceImpactWarning: any
  onChangeRecipient: (recipient: string | null) => void
  largerPriceImpact?: any
  cookieState: boolean
  setShowSign: (state: boolean) => void
  account?: string
  toggleWalletDrawer: () => void
  isExpertMode: boolean
  isValid: boolean
  priceImpactSeverity: number
  allowance: any
  swapInputError?: any
  routeIsLoading: boolean
  DetailsSwapSection: React.ElementType
  SwapDetailsDropdown: React.ElementType
  execPrice?: string
  priceImpactTooHigh: boolean
  loading: boolean
  priceImpact?: any
  gas?: any
  Text?: React.ElementType
  chainId?: number
}

const DualSwapModel: React.FC<DualSwapModelProps> = ({
  swapWidgetEnabled,
  loadedInputCurrency,
  loadedOutputCurrency,
  className,
  allowedSlippage,
  showConfirm,
  trade,
  tradeToConfirm,
  handleAcceptChanges,
  attemptingTxn,
  txHash,
  recipient,
  handleSwap,
  swapErrorMessage,
  handleConfirmDismiss,
  swapQuoteReceivedDate,
  fiatValueTradeInput,
  fiatValueTradeOutput,
  SwapSection,
  InterfaceSectionName,
  independentField,
  showWrap,
  inputAmount,
  showMaxButton,
  src,
  handleTypeInput,
  isSupportedChain,
  handleMaxInput,
  fiatValueInput,
  handleInputSelect,
  dst,
  BrowserEvent,
  routeIsSyncing,
  ArrowContainer,
  onSwitchTokens,
  fetchTrade,
  outputAmount,
  OutputSwapSection,
  theme,
  showDetailsDropdown,
  handleTypeOutput,
  fiatValueOutput,
  stablecoinPriceImpact,
  handleOutputSelect,
  showPriceImpactWarning,
  onChangeRecipient,
  largerPriceImpact,
  cookieState,
  setShowSign,
  account,
  toggleWalletDrawer,
  isExpertMode,
  isValid,
  priceImpactSeverity,
  allowance,
  swapInputError,
  routeIsLoading,
  DetailsSwapSection,
  SwapDetailsDropdown,
  execPrice,
  priceImpactTooHigh,
  loading,
  priceImpact,
  gas,
  chainId,
}) => {
  const [isSwapMode, setIsSwapMode] = useState('swap')
  const [buySearchNumber, setBuySearchNumber] = useState('')
  const [showDetails, setShowDetails] = useState(true)
  const [isShowSetting, setIsShowSetting] = useState<boolean>(false)
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false)
  const [selectedCrypto, setSelectedCrypto] = useState<string>('USDC')
  return (
    <>
      <section className="min-h-[calc(100dvh-96px)] w-full relative">
        <div className="size-48 rounded-full bg-white blur-[400px] opacity-75 absolute z-20 bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2"></div>
        <div className="w-[500px] mx-auto  max-w-[calc(100%-40px)]">
          {swapWidgetEnabled ? (
            <Widget
              defaultTokens={{
                [Field.INPUT]: loadedInputCurrency ?? undefined,
                [Field.OUTPUT]: loadedOutputCurrency ?? undefined,
              }}
              width="100%"
            />
          ) : (
            <SwapWrapper chainId={chainId} className={className} id="swap-page">
              <SwapHeader allowedSlippage={allowedSlippage} />
              <div>
                <div>
                  <div className="flex mb-5 justify-between" style={{ paddingBottom: '10px' }}>
                    <div className="flex">
                      <SwapButton onClick={() => setIsSwapMode('swap')} name="swap" isActive={isSwapMode === 'swap'}>
                        Swap
                      </SwapButton>
                      <SwapButton isActive={isSwapMode === 'dca'} name="dca" onClick={() => setIsSwapMode('dca')}>
                        DCA
                      </SwapButton>
                    </div>
                    <img
                      src="/icons/setting.png"
                      alt=""
                      style={{
                        height: '40px',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsShowSetting(true)}
                    />
                  </div>
                </div>
              </div>
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
                swapQuoteReceivedDate={swapQuoteReceivedDate}
                fiatValueInput={fiatValueTradeInput}
                fiatValueOutput={fiatValueTradeOutput}
              />

              <div>
                <>
                  <div style={{ display: 'relative' }}>
                    <SwapSection>
                      <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
                        <SwapCurrencyInputPanel
                          label={
                            independentField === Field.OUTPUT && !showWrap ? (
                              <Trans id="From (at most)"></Trans>
                            ) : (
                              <Trans id="From"></Trans>
                            )
                          }
                          value={inputAmount}
                          showMaxButton={showMaxButton}
                          currency={src ?? null}
                          onUserInput={handleTypeInput}
                          onMax={handleMaxInput}
                          fiatValue={fiatValueInput}
                          onCurrencySelect={handleInputSelect}
                          otherCurrency={dst}
                          showCommonBases={true}
                          id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                          cryptoData={cryptoData}
                          selectedCrypto={selectedCrypto}
                          loading={independentField === Field.OUTPUT && routeIsSyncing}
                        />
                      </Trace>
                    </SwapSection>
                    <ArrowWrapper clickable={isSupportedChain(Number(ChainId))}>
                      <TraceEvent
                        events={[BrowserEvent.onClick]}
                        name={SwapEventName.SWAP_TOKENS_REVERSED}
                        element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                      >
                        <ArrowContainer
                          data-testid="swap-currency-button"
                          onClick={() => {
                            onSwitchTokens()
                            const source = src
                            const dest = dst
                            useSwapStore.setState({ src: dest })
                            useSwapStore.setState({ dst: source })
                            fetchTrade()
                          }}
                          color={theme.textPrimary}
                        >
                          <img src="/img/revert.svg" alt="" />
                        </ArrowContainer>
                      </TraceEvent>
                    </ArrowWrapper>
                  </div>
                  <AutoColumn gap="md">
                    <div>
                      <OutputSwapSection showDetailsDropdown={showDetailsDropdown}>
                        <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                          <SwapCurrencyInputPanel
                            value={outputAmount}
                            onUserInput={handleTypeOutput}
                            label={
                              independentField === Field.INPUT && !showWrap ? (
                                <Trans id="To (at least)"></Trans>
                              ) : (
                                <Trans id="To"></Trans>
                              )
                            }
                            showMaxButton={false}
                            hideBalance={false}
                            fiatValue={fiatValueOutput}
                            priceImpact={stablecoinPriceImpact}
                            currency={dst ?? null}
                            onCurrencySelect={(c) => {}}
                            otherCurrency={src}
                            showCommonBases={true}
                            id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                            loading={independentField === Field.INPUT && routeIsSyncing}
                            pay={false}
                            setIsOpenShareModal={setIsOpenShareModal}
                            cryptoData={cryptoData}
                            selectedCrypto={selectedCrypto}
                          />
                        </Trace>
                      </OutputSwapSection>
                    </div>
                    {isSwapMode === 'dca' && (
                      <SwapSection>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center w-full">
                            <h1
                              style={{
                                paddingRight: '10px',
                                borderRight: '1px solid white',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Expiry period
                            </h1>
                            <InputRow
                              style={
                                true
                                  ? {
                                      paddingLeft: '10px',
                                      borderRadius: '8px',
                                      width: '100%',
                                    }
                                  : {}
                              }
                            >
                              <StyledNumericalInput
                                className="token-amount-input"
                                value={buySearchNumber}
                                onUserInput={(e) => setBuySearchNumber(e)}
                                $loading={false}
                                style={{ fontSize: '16px' }}
                              />
                            </InputRow>
                            <p style={{ paddingLeft: '10px' }}></p>
                          </div>
                          <div>
                            <p>minute</p>
                          </div>
                        </div>
                      </SwapSection>
                    )}
                    <div>
                      <ButtonError
                        onClick={() => {
                          if (!cookieState) {
                            setShowSign(true)
                            return
                          }
                          if (!account) {
                            toggleWalletDrawer()
                          }
                          if (isSwapMode === 'dca') {
                            handleSwap(false)
                            return
                          }
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            handleSwap()
                          }
                        }}
                        id="swap-button"
                        disabled={undefined}
                        error={isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                      >
                        <Text fontSize={20} fontWeight={600}>
                          <Trans id="Swap"></Trans>
                        </Text>
                      </ButtonError>
                    </div>
                  </AutoColumn>

                  <div style={{ margin: '20px 0px' }}>
                    <div className="flex justify-between items-center ">
                      <div>
                        <Text fontSize={18} fontWeight={500}>
                          <Trans id="Multi Token Swap"></Trans>
                        </Text>
                        <Text fontSize={14} fontWeight={400}>
                          <Trans id="Swap multiple tokens at once"></Trans>
                        </Text>
                      </div>
                      <SwitchWrapper>
                        <HiddenCheckbox checked={false} onChange={() => {}} />
                        <ToggleSwitch checked={false} onClick={() => {}} />
                      </SwitchWrapper>
                    </div>
                  </div>
                </>
              </div>

              <DetailsSwapSection>
                <SwapDetailsDropdown
                  price={execPrice}
                  trade={trade}
                  syncing={routeIsSyncing}
                  loading={loading}
                  allowedSlippage={allowedSlippage}
                  impact={priceImpact}
                  gas={gas}
                />
                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={SwapEventName.SWAP_DETAILS_EXPANDED}
                  element={InterfaceElementName.SWAP_DETAILS_DROPDOWN}
                  shouldLogImpression={!showDetails}
                >
                  <StyledHeaderRow
                    onClick={() => setShowDetails((prev) => !prev)}
                    open={showDetails}
                    aria-expanded={showDetails}
                    style={{ marginBottom: '10px' }}
                    disabled={false}
                  >
                    <p className="text-blue-500 cursor-pointer">{showDetails ? 'Show Less' : 'Show More'}</p>
                    <RotatingArrow open={Boolean(showDetails)} />
                  </StyledHeaderRow>
                  {showDetails && (
                    <div>
                      <div className="flex justify-between mb-2">
                        <p>Auto-Limit Order:</p>
                        <p onClick={() => setIsShowSetting(true)} className="flex cursor-pointer gap-3 items-center">
                          {' '}
                          on <ArrowLeft />
                        </p>
                      </div>
                      <div className="flex justify-between mb-2">
                        <p>Minimum Receive:</p>
                        <p>-</p>
                      </div>
                      <div className="flex justify-between mb-2">
                        <p>Network Fee:</p>
                        <p>-</p>
                      </div>
                    </div>
                  )}
                </TraceEvent>
              </DetailsSwapSection>
            </SwapWrapper>
          )}
          <NetworkAlert />
        </div>
        {isShowSetting && <SettingModal isOpen={isShowSetting} onClose={() => setIsShowSetting(false)} />}
        {isOpenShareModal && (
          <SwapShareModal
            isOpen={isOpenShareModal}
            onClose={() => setIsOpenShareModal(false)}
            cryptoData={cryptoData}
            selectedCrypto={selectedCrypto}
            setSelectedCrypto={setSelectedCrypto}
          />
        )}
      </section>
    </>
  )
}

export default DualSwapModel
