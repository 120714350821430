import { formatUnits, parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent, Trace, TraceEvent } from '@uniswap/analytics'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { sendEvent } from 'components/analytics'
import DualSwapModel from 'components/DualSwapModal/DualSwapModal'
import { ChainSelector } from 'components/NavBar/ChainSelector'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import Web3Status from 'components/Web3Status'
import Widget from 'components/Widget'
import { isSupportedChain } from 'constants/chains'
import { useSwapNameContext } from 'context/SwapNameContext'
import { Contract, ethers } from 'ethers'
import { useSwapWidgetEnabled } from 'featureFlags/flags/swapWidget'
import useENSAddress from 'hooks/useENSAddress'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import { useSwapCallback } from 'hooks/useSwapCallback'
import { useUSDPrice } from 'hooks/useUSDPrice'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { ArrowDown } from 'react-feather'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Text } from 'rebass'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import useSwapStore from 'state/store'
import styled, { useTheme } from 'styled-components/macro'
import invariant from 'tiny-invariant'
import { currencyAmountToPreciseFloat, formatTransactionAmount } from 'utils/formatNumbers'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { AutoRow } from '../../components/Row'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useCurrency, useDefaultActiveTokens } from '../../hooks/Tokens'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from '../../state/swap/hooks'
import swapReducer, { initialState as initialSwapState } from '../../state/swap/reducer'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'
import myAbi from './abi.json'

const ArrowContainer = styled.div`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  padding: 25px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`

const OutputSwapSection = styled(SwapSection)<{ showDetailsDropdown: boolean }>``

const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  background: transparent;
  backdrop-filter: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    top: -22px;
    left: -35px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    top: -22px;
    right: -35px;
  }
  & > div {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      border-top: 3px dashed #000;
      height: 0;
      top: -7px;
      left: 0;
    }
  }
`

const stables = [
  {
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    chainId: 42161,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Circle',
    symbol: 'USDC',
  },
  {
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    chainId: 42161,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Tether',
    symbol: 'USDT',
  },
  {
    address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
    chainId: 42161,
    logoURI: '/icons/USX.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'dForce USD',
    symbol: 'USX',
  },
  {
    address: '0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f',
    chainId: 42161,
    logoURI: '/icons/USDD.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'USDD',
    symbol: 'USDD',
  },
  {
    address: '0x35f1c5cb7fb977e669fd244c567da99d8a3a6850',
    chainId: 42161,
    logoURI: '/icons/USD0.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Usual',
    symbol: 'USD0',
  },
  {
    address: '0x498Bf2B1e120FeD3ad3D42EA2165E9b73f99C1e5',
    chainId: 42161,
    logoURI: '/icons/crvUSD.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Curve Stablecoin',
    symbol: 'crvUSD',
  },
  {
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    chainId: 42161,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dai',
    symbol: 'DAI',
  },
  {
    address: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
    chainId: 42161,
    logoURI: '/icons/USD+.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Overnight USD',
    symbol: 'USD+',
  },
  {
    address: '0x7751e2f4b8ae93ef6b79d86419d42fe3295a4559',
    chainId: 42161,
    logoURI: '/icons/wusdl.jpg',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'WUSDL',
    symbol: 'wUSDL',
  },
  {
    address: '0x59D9356E565Ab3A36dD77763Fc0d87fEaf85508C',
    chainId: 42161,
    logoURI: '/icons/USDM.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Mountain Protocol',
    symbol: 'USDM',
  },
  {
    address: '0x35e050d3c0ec2d29d269a8ecea763a183bdf9a9d',
    chainId: 42161,
    logoURI: '/icons/USDY.svg',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Ondo Finance',
    symbol: 'USDY',
  },
  {
    address: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
    chainId: 42161,
    logoURI: '/icons/LINK.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Chainlink',
    symbol: 'LINK',
  },
  {
    address: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
    chainId: 42161,
    logoURI: '/icons/UNI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Uniswap',
    symbol: 'Uni',
  },
  {
    address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    chainId: 42161,
    logoURI: '/icons/ARB.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Arbitrum',
    symbol: 'ARB',
  },
  {
    address: '0x9623063377ad1b27544c965ccd7342f7ea7e88c7',
    chainId: 42161,
    logoURI: '/icons/GRT.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'The Graph',
    symbol: 'GRT',
  },
  {
    address: '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
    chainId: 42161,
    logoURI: '/icons/LDO.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Lido DAO',
    symbol: 'LDO',
  },
  {
    address: '0x43333771E8d5FD74E5491d10aeAc2BFdC23D19C8',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped GameStop Corp. Class A',
    symbol: 'GME.dw',
  },
  {
    address: '0x407274ABb9241Da0A1889c1b8Ec65359dd9d316d',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped Coinbase Global, Inc. Class A Common Stock',
    symbol: 'wCOIN.d',
  },
  {
    address: '0x2F71B3804a74A013d00065225752dBA9aD061e51',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped Reddit, Inc.',
    symbol: 'RDDT.dw',
  },
]

function getIsValidSwapQuote(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function Swap({ className }: { className?: string }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [gas, setGas] = useState(0)
  const s = searchParams.get('s')
  const { account, chainId, provider } = useWeb3React()
  const { src, dst, amount, slippage, quote, fetchQuote, swap, allowance: allowanceFunc, approve } = useSwapStore()
  const dest = stables.find((item) => item.symbol == s)
  if (dest && dst === '') {
    useSwapStore.setState({ dst: dest })
  }
  const navigate = useNavigate()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
  const swapWidgetEnabled = useSwapWidgetEnabled()
  const { swapNameSelected } = useSwapNameContext()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
    useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )

  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [inputAmount, setInputAmount] = useState()
  const [outputAmount, setOutputAmount] = useState()
  const [priceImpact, setPriceImpact] = useState(0)

  // for expert mode
  const [isExpertMode] = useExpertModeManager()
  // swap state
  const [state, dispatch] = useReducer(swapReducer, initialSwapState)
  const { typedValue, recipient, independentField } = state
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )
  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const [loading, setLoading] = useState(false)

  const [execPrice, setExecPrice] = useState('')

  // Define the cookie key
  const COOKIE_KEY = 'signed'

  // Helper function to get the value of a cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }

  // Helper function to set a cookie
  const setCookie = (name, value, days) => {
    const expires = days ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}` : ''
    document.cookie = `${name}=${value}${expires}; path=/`
  }

  const [showSign, setShowSign] = useState(false)

  const [cookieState, setCookieState] = useState(() => {
    const cookieValue = getCookie(COOKIE_KEY)
    return cookieValue === 'true' // Convert cookie value to boolean
  })

  // Function to update the state and cookie
  const updateCookieState = (newValue) => {
    setCookieState(newValue)
    setCookie(COOKIE_KEY, newValue, 7) // Set cookie for 7 days
  }

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null)
    const latestCallback = useRef(callback)

    // Update the latest callback reference whenever it changes
    useEffect(() => {
      latestCallback.current = callback
    }, [callback])

    return useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          // Call the latest version of the callback
          latestCallback.current(...args)
        }, delay)
      },
      [delay] // delay is the only dependency for the debounced function
    )
  }

  const fetchTrade = async () => {
    setLoading(true)
    const qte = await fetchQuote()
    const dstAmount = qte?.dstAmount ?? undefined
    const price = qte?.price ?? undefined
    const impact = qte?.impact ?? undefined
    setPriceImpact(impact)
    setExecPrice(price)
    if (!dstAmount) {
      setLoading(false)
      return
    }
    setOutputAmount(dstAmount)
    try {
      const swapValue = await swap('0x00000000219ab540356cBB839Cbe05303d7705Fa', true)
      const gasLimit = await provider?.estimateGas({
        to: swapValue.tx.to,
        value: swapValue.tx.value,
        data: swapValue.tx.data,
        from: swapValue.tx.from,
      })
      const ethPrice = await fetch(
        `${process.env.REACT_APP_PROXY_SERVER_URL}/?url=https://api.1inch.dev/price/v1.1/${chainId}?currency=USD`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data2 = await ethPrice.json()
      const eth = data2['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee']
      setGas((formatUnits(swapValue.tx.gasPrice * gasLimit, 18) * eth).toFixed(2))
      setLoading(false)
    } catch (e) {
      setGas(0)
      setLoading(false)
    }
  }

  const handleTypeInput = (value: string) => {
    setInputAmount(value)
    useSwapStore.setState({ amount: value })
    debouncedFetchTrade()
  }

  const debouncedFetchTrade = useDebounce(fetchTrade, 300)
  const handleTypeOutput = useCallback(
    (value: string) => {
      // onUserInput(Field.OUTPUT, value)
      // console.log(Field.OUTPUT, value)
      // useSwapStore.setState({ amount: value })
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMemo(() => {
    const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
    return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, trade])
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    undefined
  )
  const isApprovalLoading = allowance.state === AllowanceState.REQUIRED && allowance.isApprovalLoading
  const [isAllowancePending, setIsAllowancePending] = useState(false)
  const updateAllowance = useCallback(async () => {
    invariant(allowance.state === AllowanceState.REQUIRED)
    setIsAllowancePending(true)
    try {
      await allowance.approveAndPermit()
      sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, {
        chain_id: chainId,
        token_symbol: maximumAmountIn?.currency.symbol,
        token_address: maximumAmountIn?.currency.address,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsAllowancePending(false)
    }
  }, [allowance, chainId, maximumAmountIn?.currency.address, maximumAmountIn?.currency.symbol])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  // const handleSwap = useCallback(() => {
  //   if (!swapCallback) {
  //     return
  //   }
  //   if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
  //     return
  //   }
  //   setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
  //   swapCallback()
  //     .then((hash) => {
  //       setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
  //       sendEvent({
  //         category: 'Swap',
  //         action: 'transaction hash',
  //         label: hash,
  //       })
  //       sendEvent({
  //         category: 'Swap',
  //         action:
  //           recipient === null
  //             ? 'Swap w/o Send'
  //             : (recipientAddress ?? recipient) === account
  //             ? 'Swap w/o Send + recipient'
  //             : 'Swap w/ Send',
  //         label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
  //           '/'
  //         ),
  //       })
  //     })
  //     .catch((error) => {
  //       setSwapState({
  //         attemptingTxn: false,
  //         tradeToConfirm,
  //         showConfirm,
  //         swapErrorMessage: error.message,
  //         txHash: undefined,
  //       })
  //     })
  // }, [
  //   swapCallback,
  //   stablecoinPriceImpact,
  //   tradeToConfirm,
  //   showConfirm,
  //   recipient,
  //   recipientAddress,
  //   account,
  //   trade?.inputAmount?.currency?.symbol,
  //   trade?.outputAmount?.currency?.symbol,
  // ])

  const delay = async () => await new Promise((resolve) => setTimeout(resolve, 2000))

  const handleSwap = async () => {
    const shouldAllow = parseUnits(amount, src.decimals)
    const allowanceValue = await allowanceFunc(account)
    if (Math.abs(allowanceValue - shouldAllow) != 0) {
      // add delay before approving
      setSwapState({
        attemptingTxn: true,
        tradeToConfirm,
        showConfirm: true,
        swapErrorMessage: undefined,
        txHash: undefined,
      })
      await delay()
      const approveValue = await approve()
      console.log('approveValue', approveValue)
      console.log('allowanceValue', allowanceValue)
      const gasLimit = await provider?.estimateGas({
        to: approveValue.to,
        value: approveValue.value,
        data: approveValue.data,
        from: account,
      })
      await provider
        ?.getSigner()
        .sendTransaction({
          ...approveValue,
          gasLimit: gasLimit?.mul(105).div(100),
        })
        .then((response) => {
          console.log('response', response)
          setSwapState({
            attemptingTxn: true,
            tradeToConfirm,
            showConfirm: false,
            swapErrorMessage: undefined,
            txHash: undefined,
          })
        })
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    })
    await delay()
    const swapValue = await swap(account)
    console.log('swapValue', swapValue)
    provider
      ?.getSigner()
      .sendTransaction({
        gasLimit: swapValue.tx.gas,
        data: swapValue.tx.data,
        from: swapValue.tx.from,
        gasPrice: swapValue.tx.gasPrice,
        to: swapValue.tx.to,
        value: swapValue.tx.value,
      })
      .then((response) => {
        console.log('response', response)
        setInputAmount(0)
        setOutputAmount(0)
        setExecPrice('')
        setSwapState({
          attemptingTxn: true,
          tradeToConfirm,
          showConfirm: true,
          swapErrorMessage: undefined,
          txHash: response.hash,
        })
      })
    // setSwapState({
    //   attemptingTxn: true,
    //   tradeToConfirm,
    //   showConfirm: true,
    //   swapErrorMessage: undefined,
    //   txHash: undefined,
    // })
  }

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      console.log(Field.INPUT, inputCurrency)
      useSwapStore.setState({ src: inputCurrency })
      fetchTrade()
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      console.log(Field.OUTPUT, outputCurrency)
      useSwapStore.setState({ dst: outputCurrency })
      fetchTrade()
    },
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        SwapEventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, trade.gasUseEstimateUSD ?? undefined, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const headerPortalContainer = document.getElementById('header-portal')

  const handleSwap2 = async (limit = true) => {
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    })
    debugger
    if (!limit) {
      const contract = new Contract('0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0', myAbi, provider?.getSigner())
      const expirationIntervalBN = await contract.expirationInterval()
      const expirationInterval = expirationIntervalBN.toNumber()

      // Calculate a valid expiration timestamp:
      // current time plus at least one expirationInterval, rounded up to a multiple of expirationInterval.
      const currentTimestamp = Math.floor(Date.now() / 10000)
      let expiration = currentTimestamp + expirationInterval
      expiration = 2200000000

      const poolKey = {
        currency0: '0x60D7A23033f0e2Ebd4A509FF7a50d19AE3096007', // token0 address used above
        currency1: '0xD446Ae173db30E2965010527D720b2539b350662',
        fee: 500,
        tickSpacing: 60,
        hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
      }
      const poolKey2 = {
        currency0: '0x60D7A23033f0e2Ebd4A509FF7a50d19AE3096007', // token0 address used above
        currency1: '0x6CCd3a7A28663D7997CdCA93796839683AB3dfD0',
        fee: 500,
        tickSpacing: 60,
        hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
      }

      const poolKey3 = {
        currency0: '0x60D7A23033f0e2Ebd4A509FF7a50d19AE3096007', // token0 address used above
        currency1: '0xdd47689da802262Eaf822a94982d929c4afA16ce',
        fee: 500,
        tickSpacing: 60,
        hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
      }
      const orderKey = {
        owner: '0x2F09Dd93f736eAc37147E339DA9c2b56bc66AD26',
        expiration,
        zeroForOne: true,
      }
      const amountIn = ethers.utils.parseEther('1.0') // Adjust the liquidity value as needed

      console.log(contract.functions)

      const tx = await contract.submitOrder(poolKey, orderKey, amountIn, {
        gasLimit: 1000000, // manually set gas limit
      })
      console.log('Transaction hash:', tx.hash)
      await tx.wait()
      await delay()
      const tx2 = await contract.submitOrder(poolKey2, orderKey, amountIn, {
        gasLimit: 1000000, // manually set gas limit
      })
      console.log('Transaction hash:', tx2.hash)
      await tx2.wait()
      await delay()
      const tx3 = await contract.submitOrder(poolKey3, orderKey, amountIn, {
        gasLimit: 1000000, // manually set gas limit
      })
      console.log('Transaction hash:', tx3.hash)
      await tx3.wait()
      await delay()
      console.log('Transaction confirmed.')
      setSwapState({
        attemptingTxn: true,
        tradeToConfirm,
        showConfirm: true,
        swapErrorMessage: undefined,
        txHash: tx3.hash,
      })
      return
    }

    // Define token0 address and minimal ERC20 ABI for approval
    const token0Address = '0x60D7A23033f0e2Ebd4A509FF7a50d19AE3096007'
    const erc20Abi = ['function approve(address spender, uint256 amount) public returns (bool)']

    // Create contract instance for token0
    const token0Contract = new Contract(token0Address, erc20Abi, provider?.getSigner())

    // Approve unlimited token spending for the contract address
    const maxApproval = ethers.constants.MaxUint256
    // const approvalTx = await token0Contract.approve('0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0', maxApproval)
    // const approvalTx2 = await token0Contract.approve('0x05E73354cFDd6745C338b50BcFDfA3Aa6fA03408', maxApproval)
    // console.log('Approval transaction hash:', approvalTx.hash)
    // await approvalTx.wait()
    console.log('Approval confirmed, unlimited token0 approved for spending.')

    // Now instantiate the main contract using your ABI
    const contract = new Contract('0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0', myAbi, provider?.getSigner())
    const contractInterface = new ethers.utils.Interface(myAbi)

    // Define parameters for the swap
    const poolKey = {
      currency0: token0Address, // token0 address used above
      currency1: '0xD446Ae173db30E2965010527D720b2539b350662',
      fee: 500,
      tickSpacing: 60,
      hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
    }

    const poolKey2 = {
      currency0: token0Address, // token0 address used above
      currency1: '0x6CCd3a7A28663D7997CdCA93796839683AB3dfD0',
      fee: 500,
      tickSpacing: 60,
      hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
    }

    const poolKey3 = {
      currency0: token0Address, // token0 address used above
      currency1: '0xdd47689da802262Eaf822a94982d929c4afA16ce',
      fee: 500,
      tickSpacing: 60,
      hooks: '0x3E86F8DEa5b8E4A36b4B93d8eB2A87A71CeC38C0',
    }

    const tickLower = 0
    const zeroForOne = true
    const liquidity = parseUnits('100', 18) // Adjust the liquidity value as needed

    const tx = await contract.place(poolKey, tickLower, zeroForOne, liquidity, {
      gasLimit: 1000000, // manually set gas limit
    })
    console.log('Transaction hash:', tx.hash)
    await tx.wait()
    console.log('Transaction confirmed.')
    await delay()
    const tx2 = await contract.place(poolKey2, tickLower, zeroForOne, liquidity, {
      gasLimit: 1000000, // manually set gas limit
    })
    console.log('Transaction hash:', tx2.hash)
    await tx2.wait()
    await delay()
    const tx3 = await contract.place(poolKey3, tickLower, zeroForOne, liquidity, {
      gasLimit: 1000000, // manually set gas limit
    })
    console.log('Transaction hash:', tx3.hash)
    await tx3.wait()

    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: tx.hash,
    })

    console.log('Order placed successfully')
  }

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <>
        {headerPortalContainer &&
          ReactDOM.createPortal(
            <div className="flex justify-between items-center h-full px-6">
              <h1 className="text-xl font-semibold text-white">Swap</h1>
              <div className="flex space-x-2">
                <ChainSelector />
                <Web3Status />
              </div>
            </div>,
            headerPortalContainer
          )}
        {!cookieState && showSign && (
          <div className="fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-lg z-[1001]">
            <div className="w-[500px] absolute top-1/2 left-0 right-0 m-auto -translate-y-1/2 rounded-[20px] border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.06)] p-[32px] space-y-[32px] text-center">
              <img src="/img/sig.png" className="absolute w-full h-full left-0 top-0 z-[-1]" alt="" />
              <img src="/img/logo2.png" className="size-[221px] block mx-auto" alt="" />
              <p className="text-[24px] text-white">Sign For Access</p>
              <div className="space-y-[10px]">
                <p className="text-white leading-relaxed text-[14px]">
                  By signing this message, you confirm that you are not accessing this app from, or are a resident of
                  the USA or any restricted country.
                </p>
                <p className="text-white leading-relaxed text-[14px]">For more information, please read our</p>
                <p>
                  <a
                    href="https://docs.newera.finance/legal/terms-of-service"
                    className="text-white font-bold text-[14px]"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
              <button
                onClick={() => updateCookieState('true')}
                className='shadow-[0px_4px_114px_0px_rgba(118,5,232,0.63)] cursor-pointer w-full h-[52px] text-white bg-[url("./img/but.png")] bg-cover rounded-[12px] text-[16px]'
              >
                Sign Message
              </button>
            </div>
          </div>
        )}

        <TokenSafetyModal
          isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
          tokenAddress={importTokensNotInDefault[0]?.address}
          secondTokenAddress={importTokensNotInDefault[1]?.address}
          onContinue={handleConfirmTokenWarning}
          onCancel={handleDismissTokenWarning}
          showCancel={true}
        />

        {/* <PageWrapper> */}
        {chainId === 84532 ? (
          <DualSwapModel
            swapWidgetEnabled={swapWidgetEnabled}
            loadedInputCurrency={loadedInputCurrency}
            loadedOutputCurrency={loadedOutputCurrency}
            className={className}
            allowedSlippage={allowedSlippage}
            showConfirm={showConfirm}
            trade={trade}
            tradeToConfirm={tradeToConfirm}
            handleAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            handleSwap={handleSwap2}
            swapErrorMessage={swapErrorMessage}
            handleConfirmDismiss={handleConfirmDismiss}
            swapQuoteReceivedDate={swapQuoteReceivedDate}
            fiatValueTradeInput={fiatValueTradeInput}
            fiatValueTradeOutput={fiatValueTradeOutput}
            SwapSection={SwapSection}
            InterfaceSectionName={InterfaceSectionName}
            independentField={independentField}
            inputAmount={inputAmount}
            showMaxButton={showMaxButton}
            src={src}
            handleTypeInput={handleTypeInput}
            isSupportedChain={isSupportedChain}
            handleMaxInput={handleMaxInput}
            fiatValueInput={fiatValueInput}
            handleInputSelect={handleInputSelect}
            dst={dst}
            BrowserEvent={BrowserEvent}
            routeIsSyncing={routeIsSyncing}
            ArrowContainer={ArrowContainer}
            onSwitchTokens={onSwitchTokens}
            fetchTrade={fetchTrade}
            OutputSwapSection={OutputSwapSection}
            theme={theme}
            outputAmount={outputAmount}
            showDetailsDropdown={showDetailsDropdown}
            handleTypeOutput={handleTypeOutput}
            fiatValueOutput={fiatValueOutput}
            stablecoinPriceImpact={stablecoinPriceImpact}
            handleOutputSelect={handleOutputSelect}
            showPriceImpactWarning={showPriceImpactWarning}
            onChangeRecipient={onChangeRecipient}
            largerPriceImpact={largerPriceImpact}
            cookieState={cookieState}
            setShowSign={setShowSign}
            account={account}
            toggleWalletDrawer={toggleWalletDrawer}
            isExpertMode={isExpertMode}
            isValid={isValid}
            priceImpactSeverity={priceImpactSeverity}
            allowance={allowance}
            swapInputError={swapInputError}
            routeIsLoading={routeIsLoading}
            DetailsSwapSection={DetailsSwapSection}
            SwapDetailsDropdown={SwapDetailsDropdown}
            execPrice={execPrice}
            priceImpactTooHigh={priceImpactTooHigh}
            loading={loading}
            priceImpact={priceImpact}
            gas={gas}
            Text={Text}
            chainId={chainId}
            showWrap={showWrap}
          />
        ) : (
          <section className="min-h-[calc(100dvh-96px)] w-full relative">
            {/* <img
            src="/img/border.png"
            className="w-[1121px] left-0 right-0 mx-auto bottom-[100px] absolute max-sm:hidden"
          /> */}
            <div className="size-48 rounded-full bg-white blur-[400px] opacity-75 absolute z-20 bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2"></div>
            {/* <div className="w-full h-1/2 absolute left-0 top-0 bg-[url(./img/pattern.png)] mix-blend-color-burn opacity-50"></div> */}
            <div className="w-[500px] absolute left-0 right-0 mx-auto top-1/2 -translate-y-1/2 max-w-[calc(100%-40px)]">
              {swapWidgetEnabled ? (
                <Widget
                  defaultTokens={{
                    [Field.INPUT]: loadedInputCurrency ?? undefined,
                    [Field.OUTPUT]: loadedOutputCurrency ?? undefined,
                  }}
                  width="100%"
                />
              ) : (
                <SwapWrapper chainId={chainId} className={className} id="swap-page">
                  <SwapHeader allowedSlippage={allowedSlippage} />

                  <ConfirmSwapModal
                    isOpen={showConfirm}
                    trade={trade}
                    originalTrade={tradeToConfirm}
                    onAcceptChanges={handleAcceptChanges}
                    attemptingTxn={attemptingTxn}
                    txHash={txHash}
                    recipient={recipient}
                    allowedSlippage={allowedSlippage}
                    onConfirm={handleSwap}
                    swapErrorMessage={swapErrorMessage}
                    onDismiss={handleConfirmDismiss}
                    swapQuoteReceivedDate={swapQuoteReceivedDate}
                    fiatValueInput={fiatValueTradeInput}
                    fiatValueOutput={fiatValueTradeOutput}
                  />

                  <div style={{ display: 'relative' }}>
                    <SwapSection>
                      <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
                        <SwapCurrencyInputPanel
                          label={
                            independentField === Field.OUTPUT && !showWrap ? (
                              <Trans>From (at most)</Trans>
                            ) : (
                              <Trans>From</Trans>
                            )
                          }
                          value={inputAmount}
                          showMaxButton={showMaxButton}
                          currency={src ?? null}
                          onUserInput={handleTypeInput}
                          onMax={handleMaxInput}
                          fiatValue={fiatValueInput}
                          onCurrencySelect={handleInputSelect}
                          otherCurrency={dst}
                          showCommonBases={true}
                          id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                          loading={independentField === Field.OUTPUT && routeIsSyncing}
                        />
                      </Trace>
                    </SwapSection>
                    <ArrowWrapper clickable={isSupportedChain(chainId)}>
                      <TraceEvent
                        events={[BrowserEvent.onClick]}
                        name={SwapEventName.SWAP_TOKENS_REVERSED}
                        element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                      >
                        <ArrowContainer
                          data-testid="swap-currency-button"
                          onClick={() => {
                            onSwitchTokens()
                            const source = src
                            const dest = dst
                            useSwapStore.setState({ src: dest })
                            useSwapStore.setState({ dst: source })
                            fetchTrade()
                          }}
                          color={theme.textPrimary}
                        >
                          {/* <ArrowDown
                        size="16"
                        color={
                          currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.textPrimary : theme.textTertiary
                        }
                      /> */}
                          <img src="/img/revert.svg" alt="" />
                        </ArrowContainer>
                      </TraceEvent>
                    </ArrowWrapper>
                  </div>
                  <AutoColumn gap="md">
                    <div>
                      <OutputSwapSection showDetailsDropdown={showDetailsDropdown}>
                        <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                          <SwapCurrencyInputPanel
                            value={outputAmount}
                            onUserInput={handleTypeOutput}
                            label={
                              independentField === Field.INPUT && !showWrap ? (
                                <Trans>To (at least)</Trans>
                              ) : (
                                <Trans>To</Trans>
                              )
                            }
                            showMaxButton={false}
                            hideBalance={false}
                            fiatValue={fiatValueOutput}
                            priceImpact={stablecoinPriceImpact}
                            currency={dst ?? null}
                            onCurrencySelect={handleOutputSelect}
                            otherCurrency={src}
                            showCommonBases={true}
                            id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                            loading={independentField === Field.INPUT && routeIsSyncing}
                            pay={false}
                          />
                        </Trace>

                        {recipient !== null && !showWrap ? (
                          <>
                            <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                              <ArrowWrapper clickable={false}>
                                <ArrowDown size="16" color={theme.textSecondary} />
                              </ArrowWrapper>
                              <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                                <Trans>- Remove recipient</Trans>
                              </LinkStyledButton>
                            </AutoRow>
                            <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                          </>
                        ) : null}
                      </OutputSwapSection>
                    </div>
                    {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                    <div>
                      {/* {swapIsUnsupported ? (
                      <ButtonPrimary disabled={true}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Unsupported Asset</Trans>
                        </ThemedText.DeprecatedMain>
                      </ButtonPrimary>
                    ) : !account ? (
                      <TraceEvent
                        events={[BrowserEvent.onClick]}
                        name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                        properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                      >
                        <ButtonLight onClick={toggleWalletDrawer} fontWeight={600}>
                          <Trans>Connect Wallet</Trans>
                        </ButtonLight>
                      </TraceEvent>
                    ) : showWrap ? (
                      <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap} fontWeight={600}>
                        {wrapInputError ? (
                          <WrapErrorText wrapInputError={wrapInputError} />
                        ) : wrapType === WrapType.WRAP ? (
                          <Trans>Wrap</Trans>
                        ) : wrapType === WrapType.UNWRAP ? (
                          <Trans>Unwrap</Trans>
                        ) : null}
                      </ButtonPrimary>
                    ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                      <GrayCard style={{ textAlign: 'center' }}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Insufficient liquidity for this trade.</Trans>
                        </ThemedText.DeprecatedMain>
                      </GrayCard>
                    ) : isValid && allowance.state === AllowanceState.REQUIRED ? (
                      <ButtonPrimary
                        onClick={updateAllowance}
                        disabled={isAllowancePending || isApprovalLoading}
                        style={{ gap: 14 }}
                      >
                        {isAllowancePending ? (
                          <>
                            <Loader size="20px" />
                            <Trans>Approve in your wallet</Trans>
                          </>
                        ) : isApprovalLoading ? (
                          <>
                            <Loader size="20px" />
                            <Trans>Approval pending</Trans>
                          </>
                        ) : (
                          <>
                            <div style={{ height: 20 }}>
                              <MouseoverTooltip
                                text={
                                  <Trans>
                                    Permission is required for Uniswap to swap each token. This will expire after one
                                    month for your security.
                                  </Trans>
                                }
                              >
                                <Info size={20} />
                              </MouseoverTooltip>
                            </div>
                            <Trans>Approve use of {currencies[Field.INPUT]?.symbol}</Trans>
                          </>
                        )}
                      </ButtonPrimary>
                    ) : ( */}
                      <ButtonError
                        onClick={() => {
                          if (!cookieState) {
                            setShowSign(true)
                            return
                          }
                          if (!account) {
                            toggleWalletDrawer()
                          }
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            handleSwap()
                            // setSwapState({
                            //   tradeToConfirm: trade,
                            //   attemptingTxn: false,
                            //   swapErrorMessage: undefined,
                            //   showConfirm: true,
                            //   txHash: undefined,
                            // })
                          }
                        }}
                        id="swap-button"
                        disabled={account && (!outputAmount || outputAmount === 0)}
                        error={isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                      >
                        <Text fontSize={20} fontWeight={600}>
                          {swapInputError ? (
                            swapInputError
                          ) : routeIsSyncing || routeIsLoading ? (
                            <Trans>{!outputAmount || outputAmount === 0 ? 'Enter input amount' : 'Swap'}</Trans>
                          ) : priceImpactTooHigh ? (
                            <Trans>Price Impact Too High</Trans>
                          ) : priceImpactSeverity > 2 ? (
                            <Trans>Swap Anyway</Trans>
                          ) : (
                            <Trans>Swap</Trans>
                          )}
                        </Text>
                      </ButtonError>
                      {/* )} */}
                      {/* {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
                    </div>
                  </AutoColumn>
                  {/* {showDetailsDropdown && ( */}

                  <DetailsSwapSection>
                    <SwapDetailsDropdown
                      price={execPrice}
                      trade={trade}
                      syncing={routeIsSyncing}
                      loading={loading}
                      allowedSlippage={allowedSlippage}
                      impact={priceImpact}
                      gas={gas}
                    />
                  </DetailsSwapSection>
                  {/* )} */}
                </SwapWrapper>
              )}
              <NetworkAlert />
            </div>
          </section>
        )}
        {/* </PageWrapper> */}
        <SwitchLocaleLink />
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
