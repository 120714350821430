import React, { createContext, useState, useContext, Dispatch, SetStateAction } from 'react';

interface SwapNameContextType {
  swapNameSelected: string | null;
  setSwapNameSelected: Dispatch<SetStateAction<string | null>>;
}

const SwapNameContext = createContext<SwapNameContextType>({
  swapNameSelected: null, 
  setSwapNameSelected: () => {}
});

export const useSwapNameContext = () => {
  return useContext(SwapNameContext);
};

export const SwapNameProvider = ({ children }: { children: React.ReactNode }) => {
  const [swapNameSelected, setSwapNameSelected] = useState<string | null>(null);

  return (
    <SwapNameContext.Provider value={{ swapNameSelected, setSwapNameSelected }}>
      {children}
    </SwapNameContext.Provider>
  );
};
