import { useAccountDrawer } from "components/AccountDrawer";
import IconButton from "components/AccountDrawer/IconButton";
import { BaseButton } from "components/Button";
import { AutoRow } from "components/Row";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import styled, { useTheme } from "styled-components/macro";
import { BREAKPOINTS, ThemedText } from "theme";
import { flexColumnNoWrap } from "theme/styles";
import { Z_INDEX } from "theme/zIndex";
import StockCard from "./StockCard";
const DRAWER_TOP_MARGIN_MOBILE_WEB = "72px";

export interface ICryptoData {
  name: string,
  subtext: string,
  image: string,
}

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  width: 100%;
  padding: 32px;
  flex: 1;
`;
const ScrimBackground = styled.div<{ open: boolean }>`
  z-index: ${Z_INDEX.modalBackdrop};
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? "auto" : "none")};
    transition: opacity ${({ theme }) => theme.transition.duration.medium}
      ease-in-out;
  }
`;

const AccountDrawerWrapper = styled.div<{ open: boolean }>`
  overflow: hidden;
  width: 70vw;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    z-index: ${Z_INDEX.modal};
    position: absolute;
    margin-right: 0;
    top: ${({ open }) =>
      open ? `calc(-1 * (100% - ${DRAWER_TOP_MARGIN_MOBILE_WEB}))` : 0};

    width: 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: unset;
    transition: top ${({ theme }) => theme.transition.duration.medium};
  }

  @media screen and (min-width: 1440px) {
  }

  border-radius: 12px;
  font-size: 16px;
  border-radius: 28px;

  background: #171717;
  z-index: 1041;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    mask-size: 100% 50%;
    mask-repeat: no-repeat;
    z-index: -1;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background: rgba(255, 255, 255, 0.04);
  font-size: 16px;
  * {
    font-size: 16px !important;
  }
  padding: 13px;
  margin: 10px 0;
  color: #fff;
  border: none;
  &:focus {
  }
  &:hover {
  }
  &:active {
  }
  &:disabled {
    cursor: auto;
    outline: none;
    filter: grayscale(1);
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(22px);
  overflow: hidden;
  position: fixed;
  z-index: ${Z_INDEX.fixed};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible;
  }
`;
const AccountDrawerScrollWrapper = styled.div`
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }

  border-radius: 12px;
`;

const ProgressBarWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  padding: 8px;
  height: 50px;
  background: ${({ color }) => color};
`;

const SwapSection = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  padding: 25px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  background: transparent;
  backdrop-filter: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    bottom: 0;
    left: 0;
  }
  & > div {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      border-left: 3px dashed #000;
      height: 0;
      top: 0;
      left: 0;
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.03);
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TokenIcon = styled.div<{ image: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${({ image }) => image}) no-repeat center;
  background-size: contain;
`;

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

const TokenSubtext = styled.span`
  font-size: 11px;
  color: #999;
`;

const AddButton = styled.button`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #333;
  color: white;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
`;

const ToggleWrapper = styled.div`
  display: flex;
  width: 100% !important;
  gap: 5px;
  text-align: center;
  background: #151517;
  padding: 4px;
  border-radius: 20px;
  width: fit-content;
`;

const ToggleButton = styled.div<{ active?: boolean }>`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  flex: 1;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  background: ${({ active }) => (active ? "white" : "#232323")};
  color: ${({ active }) => (active ? "black" : "#666")};

  &:hover {
    background: ${({ active }) => (active ? "white" : "#2f2f2f")};
  }
`;

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 25px;
  padding: 12px 18px;
  width: 100%;
  max-width: 400px;
  position: relative;
  overflow-x : auto;
`;

const SearchInput = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  color: #8c8c8c;
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: #8c8c8c;
  }
`;

const ExtraText = styled.div`
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 500;
`;

const PercentageBox = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderWraper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 0.6fr 0fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  align-items: center;
  position: relative;
`;

const UpperCircle = styled.div`
  position: absolute;
  z-index: 100000;
  height: 35px;
  width: 35px;
  background: #0A090D;
  border-radius: 35px;
  left: calc((100% - 64px) * 0.3 + 30.5px);
  top: -17.5px;
`;
const LowerCircle = styled.div`
  position: absolute;
  z-index: 100000;
  height: 35px;
  width: 35px;
  background: #0A090D;
  border-radius: 35px;
  left: calc((100% - 64px) * 0.3 + 30.5px);
  bottom: -17.5px;
`;
const DashedBorder = styled.div`
  position: absolute;
  width: 0;
  border-left: 3px dashed #0A090D;
  height: 100%;
  top: 0;
  left: calc((100% - 64px) * 0.3 + 47px);
`;

export default function SwapShareModal({ className , isOpen , onClose , selectedCrypto, setSelectedCrypto , cryptoData}: { className?: string , isOpen : boolean , onClose : ()=> void , setSelectedCrypto: React.Dispatch<React.SetStateAction<string>>, selectedCrypto: string , cryptoData : ICryptoData[]}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState("assets");

  return (
    <Container>
      <AccountDrawerWrapper open={false}>
        <UpperCircle></UpperCircle>
        <LowerCircle></LowerCircle>
        <DashedBorder></DashedBorder>
        <AccountDrawerScrollWrapper
          ref={scrollRef}
          id="wallet-dropdown-scroll-wrapper"
        >
          <Wrapper data-testid="setting-modal">
            <ThemedText.SubHeader
              width="100%"
              marginBottom="32px"
              className="flex"
            >
              <AutoRow
                width="30%"
                height="100%"
                gap="5px"
              >
                <ThemedText.SubHeader
                  fontWeight={500}
                  className="flex gap-2"
                  padding={"0px 10px 20px 10px"}
                >
                  Select Assets
                </ThemedText.SubHeader>


                <SearchBarWrapper>
                  <img src="/icons/searchIcon.png" alt="" style={{width : '20px' , height :'20px' , marginRight : '8px'}} />
                  <SearchInput placeholder="Search Assets" />
                  <ExtraText>+35</ExtraText>
                </SearchBarWrapper>

                <ToggleWrapper>
                  <ToggleButton
                    active={selected === "assets"}
                    onClick={() => setSelected("assets")}
                  >
                    Assets
                  </ToggleButton>
                  <ToggleButton
                    active={selected === "portfolio"}
                    onClick={() => setSelected("portfolio")}
                  >
                    Portfolio
                  </ToggleButton>
                </ToggleWrapper>
                {cryptoData.map((token, index) => (
                  <CardWrapper key={index}>
                    <LeftSection>
                      <TokenIcon image={token.image} />
                      <TokenInfo>
                        <TokenName>{token.name}</TokenName>
                        <TokenSubtext>{token.subtext}</TokenSubtext>
                      </TokenInfo>
                    </LeftSection>
                    <AddButton onClick={() => setSelectedCrypto(token.name)}>
                      Add
                    </AddButton>
                  </CardWrapper>
                ))}
              </AutoRow>
              <AutoRow
                width={"70%"}
                height={"100%"}
                paddingLeft={"55px !important"}
              >
                <AutoRow
                  justify="space-between"
                  width="100%"
                  marginBottom="32px"
                >
                  <ThemedText.SubHeader>Assets Share</ThemedText.SubHeader>
                  <IconButton
                    Icon={X}
                    onClick={()=> onClose()}
                    data-testid="wallet-settings"
                    className="!bg-transparent"
                    
                  />
                </AutoRow>
                <HeaderWraper>
                  <ProgressBarWrapper color="#FFFFFF"></ProgressBarWrapper>
                  <ProgressBarWrapper color="#783CFF"></ProgressBarWrapper>
                  <ProgressBarWrapper color="#FF4D89"></ProgressBarWrapper>
                  <PercentageBox>100 %</PercentageBox>
                  <PercentageBox>
                    {" "}
                    <IconButton
                      Icon={() => <img src="/icons/rotate.png" alt="rotate" />}
                      onClick={()=> {}}
                      data-testid="wallet-settings"
                      className="!bg-transparent"
                    />
                  </PercentageBox>
                </HeaderWraper>

                <div
                  style={{
                    width: "100%",
                    minHeight: "50vh",
                  }}
                >
                  {cryptoData
                    .filter((stock) => selectedCrypto !== stock.name)
                    .map((stock, index) => (
                      <StockCard
                        key={stock.name} // Using a unique key (preferably `id` if available)
                        {...stock}
                        percentage={[50, 30, 20][index] || 20} // More scalable way to set percentages
                         color={
                          ["#FFFFFF", "#783CFF", "#FF4D89"][index] || "#FFFFFF"
                        } // More scalable way to set colors
                      />
                    ))}
                </div>
                <ToggleWrapper
                  style={{ marginLeft: "auto", maxWidth: "400px" }}
                >
                  <ToggleButton active={false} style={{ borderRadius: "12px" ,marginRight : '5px'}} onClick={()=> onClose()}>
                    Clear All Assets
                  </ToggleButton>
                  <ToggleButton active={true} style={{ borderRadius: "12px" }} onClick={()=> onClose()}>
                    Confirm
                  </ToggleButton>
                </ToggleWrapper>
              </AutoRow>
              {/* </DetailsSwapSection> */}
            </ThemedText.SubHeader>
          </Wrapper>
        </AccountDrawerScrollWrapper>
      </AccountDrawerWrapper>
    </Container>
  );
}

