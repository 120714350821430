

import styled from "styled-components";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 6fr 0.8fr 0.3fr;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 10px;
  margin: 10px 0px;
  width: 100%;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: white;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: #888;
`;

const PercentageBox = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  padding: 8px;
  width: 100%;
`;

const ProgressTrack = styled.div`
  flex: 1;
  height: 50px;
  background: rgba(255, 255, 255, 0.04);
  margin: 0 10px;
  border-radius: 12px;
  position: relative;
`;

const ProgressFill = styled.div<{ progress: number; color: string }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background:${({ color }) => color}};
  border-radius: 12px;
  transition: width 0.3s ease-in-out;
  position: relative;

  &::before {
    content: "|||";
    position: absolute;
    top: 12px;
    right: 6px;
    color: #000;
    transition: left 0.3s ease-in-out;
  }
`;

const StockCard = ({ name, subtext, image, color, percentage }: any) => {
  return (
    <CardWrapper>
      <Logo src={image} alt={name} />
      <Info>
        <Title>{name}</Title>
        <Subtitle>{subtext}</Subtitle>
      </Info>
      <ProgressBarWrapper>
        <ProgressTrack>
          <ProgressFill progress={percentage} color={color} />
        </ProgressTrack>
      </ProgressBarWrapper>
      <PercentageBox>{percentage} %</PercentageBox>
      <CloseButton>✕</CloseButton>
    </CardWrapper>
  );
};

export default StockCard;
